<template>
	<div class="terms-of-use">

	
		<div class="context-container mt-4">
			<h2 class="context-title">NLSE CONTENT TERMS OF USE</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<b>Effective Date</b>: September 30, 2023 <br />
			</p>

			<!-- THIS IS THE MAIN PARAGRAPH SECTION -->
			<p class="context-desc" 
				v-for="paragraph in main_paragraphs" 
				:key="paragraph.id"
				v-html="applyBoldTag(paragraph, { 
					'“NLSE+”': '“NLSE+”', 
					'“Agreement”': '“Agreement”', 
					'“You”': '“You”', 
					'“you”': '“you”', 
					'“your”': '“your”', 
					'“Users”': '“Users”', 
					'“Content”': '“Content”', 
					'“Platform”': '“Platform”', 
					'“Services”': '“Services”', 
					'“Effective Date”': '“Effective Date”' 
				})"
			>
			</p>

			<!-- THIS IS THE DEFINITION SECTION OF THE TERMS OF SERVICE-->
			<h2 class="context-title">1. DEFINITIONS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ definitions.paragraph }}
			</p>

			<!-- THIS IS THE TERM SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">2. TERM</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ terms.paragraph }}

				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in terms.listItems" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						<span 
							style="font-size: 1.35rem;"
							v-html="applyBoldTag(listItem.item_description, 
							{
								'“Subscription Election”': '“Subscription Election”',
								'“Paid Subscriptions”': '“Paid Subscriptions”',
								'“Trial Subscription”': '“Trial Subscription”',
								'“Trial Period”': '“Trial Period”',
								'“Trial Termination Notice”': '“Trial Termination Notice”',
								'“Monthly Paid Subscription”': '“Monthly Paid Subscription”',
								'“Annual Paid Subscription”': '“Annual Paid Subscription”'
							},
							{
								'plus.nlse.com/account': 'https://plus.nlse.com/account'
							}
							)"
						></span>
					</li>
				</ul>
			</p>

			<!-- THIS IS THE MODIFICATIONS SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">3. MODIFICATIONS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ modifications.paragraph }}
			</p>

			<!-- THIS IS THE ELIGIBILITY SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">4. ELIGIBILITY</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ eligibility.paragraph }}
			</p>

			<!-- THIS IS THE ACCOUNTS SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">5. ACCOUNTS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in accounts" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						<span 
							style="font-size: 1.35rem;"
							v-html="applyBoldTag(
								listItem.item_description, 
								{
									'“Account”': '“Account”',
									'“Account ID”': '“Account ID”',
									'“Account Information”': '“Account Information”'
								}
							)"
						></span>
					</li>
				</ul>
			</p>

			<!-- THIS IS THE ACCESS SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">6. ACCESS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in access" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						<span 
							style="font-size: 1.35rem;"
							v-html="applyBoldTag(
								listItem.item_description, 
								{
									'“Site”': '“Site”',
									'“Application”': '“Application”',
									'“Platform Content”': '“Platform Content”'
								},
								{
									'nlseplussupport@nlse.com': 'mailto:nlseplussupport@nlse.com'
								}
							)"
						></span>
					</li>
				</ul>
			</p>

			<!-- THIS IS THE CONTENT SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">7. YOUR CONTENT</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					your_content.paragraph,
					{
						'“Your Content”': '“Your Content”',
						'“Use”': '“Use”'
					}
				)"
			>
			</p>

			<!-- THIS IS THE DATA PRIVACY SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">8. DATA PRIVACY</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in data_privacy" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						<span 
							style="font-size: 1.35rem;"
							v-html="applyBoldTag(
								listItem.item_description, 
								{
									'“Privacy Policy”': '“Privacy Policy”',
								}
							)"
						></span>
					</li>
				</ul>
			</p>

			<!-- THIS IS THE FEES SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">9. FEES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					fees.paragraph,
					{
						'“Fees”': '“Fees”',
					},
					{
						'plus.nlse.com/accounts': 'https://plus.nlse.com/account'
					}
				)"
			>
			</p>

			<!-- THIS IS THE PRICING CHANGES SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">10. PRICING CHANGES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					pricing_changes.paragraph,
					null,
					{
						'plus.nlse.com/accounts': 'https://plus.nlse.com/account'
					}
				)"
			>
			</p>

			<!-- THIS IS THE TERMINATION AND EFFECT SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">11. TERMINATION AND EFFECT</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in termination_effect" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						{{ listItem.item_description }}
					</li>
				</ul>
			</p>

			<!-- THIS IS THE SUSPENSION SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">12. SUSPENSION</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ suspension.paragraph }}
			</p>

			<!-- THIS IS THE RESTARTING SERVICE SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">13. RESTARTING SERVICES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ restarting_services.paragraph }}
			</p>

			<!-- THIS IS THE PLATFORM TECHNOLOGY SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">14. PLATFORM TECHNOLOGY</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					platform_technology.paragraph,
					{
						'“Technology”': '“Technology”',
						'“IPR”': '“IPR”'
					}
				)"
			>
			</p>

			<!-- THIS IS THE REPRESENTATIVES AND WARRANTIES SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">15. REPRESENTATIONS AND WARRANTIES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ representations_warranties.paragraph }}
			</p>

			<!-- THIS IS THE DISCLAIMERS SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">16. DISCLAIMERS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				<ul class="context-desc ml-4">
					<li 
						class="my-2"
						v-for="listItem in disclaimers" 
						:key="listItem.id"
					>
						<b>{{ listItem.item_title }}</b>&nbsp;
						{{ listItem.item_description }}
					</li>
				</ul>
			</p>

			<!-- THIS IS THE INDEMNITY SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">17. INDEMNITY</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					indemnity.paragraph,
					{
						'“Indemnified Parties”': '“Indemnified Parties”',
					}
				)"
			>
			</p>

			<!-- THIS IS THE LIMITATION ON LIABILITY SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">18. LIMITATION ON LIABILITY</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					limitation_liability.paragraph,
					{
						'WILL  NOT': 'WILL  NOT',
					}
				)"
			>
			</p>

			<!-- THIS IS THE CLAIMS OF INFRINGEMENT SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">19. CLAIMS OF INFRINGEMENT</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ claims_of_infringement.starting_paragraph }}

				<ul class="context-desc ml-4">
					<li 
						v-for="listItem in claims_of_infringement.list_items" 
						:key="listItem.id">
						{{ listItem }}
					</li>
				</ul>

				<span
					style="font-size: 1.35rem;"
					v-html="applyBoldTag(
						claims_of_infringement.ending_paragraph,
						{
							'“DMCA”': '“DMCA”'
						}
					)"
				></span>
			</p>

			<!-- THIS IS THE DISPUTES SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">20. DISPUTES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc"
				v-html="applyBoldTag(
					disputes.paragraph,
					{
						'“Dispute”': '“Dispute”',
						'“JAMS”': '“JAMS”',
						'“Rules”': '“Rules”',
						'“Initial Period”': '“Initial Period”',
					}
				)"
			>
			</p>

			<!-- THIS IS THE GOVERNING LAW AND VENUE SECTION OF THE TERMS OF SERVICE -->
			<h2 class="context-title">21. GOVERNING LAW AND VENUE</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ governing_law_venue.paragraph }}
			</p>

			<!-- THIS IS THE NOTICES SECTION OF THE TERMS OF SERVICES -->
			<h2 class="context-title">22. NOTICES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ notices.paragraph }}
			</p>

			<!-- THIS IS THE LINKED SITES SECTION OF THE TERMS OF SERVICES -->
			<h2 class="context-title">23. LINKED SITES</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ linked_sites.paragraph }}
			</p>

			<!-- THIS IS THE ADDITIONAL TERMS SECTION OF THE TERMS OF SERVICES -->
			<h2 class="context-title">24. ADDITIONAL TERMS</h2>
			<div class="line-yellow"></div>
			<p class="context-desc">
				{{ additional_terms.paragraph }}
			</p>
		</div>
	</div>
</template>

<script>
import { getTermsOfServiceContent } from "@/services/termsOfService";
export default {
	data() {
		return {
			// lastUpdated: "",
			main_paragraphs: [],
			definitions: {},
			terms: {},
			modifications: {},
			eligibility: {},
			accounts: [],
			access: [],
			your_content: {},
			data_privacy: [],
			fees: {},
			pricing_changes: {},
			termination_effect: [],
			suspension: {},
			restarting_services: {},
			platform_technology: {},
			representations_warranties: {},
			disclaimers: [],
			indemnity: {},
			limitation_liability: {},
			claims_of_infringement: {},
			disputes: {},
			governing_law_venue: {},
			notices: {},
			linked_sites: {},
			additional_terms: {},
		};
	},
	mounted() {
		this.updateLastUpdated();
	},
	methods: {
		applyBoldTag(paragraph, itemsForBoldTags, itemForAnchorTags){
			if(itemsForBoldTags) {
				const regexForBoldTags = new RegExp(Object.keys(itemsForBoldTags).join("|"),"gi");
				paragraph = paragraph.replace(regexForBoldTags, function(matched){
					return `<b>${itemsForBoldTags[matched]}</b>`;
				});
			}

			if(itemForAnchorTags) {
				const regexForAnchorTags = new RegExp(Object.keys(itemForAnchorTags).join("|"),"gi");
				paragraph = paragraph.replace(regexForAnchorTags, function(matched){
					return `<a style="color: #EBBA0A" target="_black" href="${itemForAnchorTags[matched]}">${matched}</a>`;
				});
			}

			return paragraph;
		},
		updateLastUpdated() {
			// const currentDate = new Date();
			// const options = {
			// 	year: "numeric",
			// 	month: "long",
			// 	day: "numeric",
			// };
			// this.lastUpdated = currentDate.toLocaleString("en-US", options);
			// const formattedDate = this.lastUpdated;
			// this.lastUpdated = `${formattedDate}`;

			const {
				mainParagraph,
				definitions,
				terms,
				modifications,
				eligibility,
				accounts,
				access,
				your_content,
				data_privacy,
				fees,
				pricing_changes,
				termination_effect,
				suspension,
				restarting_services,
				platform_technology,
				representations_warranties,
				disclaimers,
				indemnity,
				limitation_liability,
				claims_of_infringement,
				disputes,
				gaverning_law_venue,
				notices,
				linked_sites,
				additional_terms,
			} = getTermsOfServiceContent();

			// The main paragraphs for the terms of service 
			this.main_paragraphs = mainParagraph.map(({ paragraph }) => paragraph);

			// The definition section of the terms of service
			this.definitions.paragraph = definitions.paragraph;

			// The terms section of the terms of service
			this.terms.paragraph = terms.paragraph;
			this.terms.listItems = terms.list_items.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The modifications section of the terms of service
			this.modifications.paragraph = modifications.paragraph;

			// The eligibility section of the terms of service
			this.eligibility.paragraph = eligibility.paragraph;

			// The accounts section of the terms of service
			this.accounts = accounts.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The access section of the terms of service
			this.access = access.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The your content section of the terms of service
			this.your_content.paragraph = your_content.paragraph;

			// The data privacy section of the terms of service
			this.data_privacy = data_privacy.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The fees section of the terms of service
			this.fees.paragraph = fees.paragraph;

			// The pricing changes section of the terms of service
			this.pricing_changes.paragraph = pricing_changes.paragraph;

			// The termination and effect section of the terms of service
			this.termination_effect = termination_effect.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The suspension section of the terms of service
			this.suspension.paragraph = suspension.paragraph;

			// The restarting services section of the terms of service
			this.restarting_services.paragraph = restarting_services.paragraph;

			// The platform technology section of the terms of service
			this.platform_technology.paragraph = platform_technology.paragraph;

			// The representations and warranties section of the terms of service
			this.representations_warranties.paragraph = representations_warranties.paragraph;

			// The disclaimers section of the terms of service
			this.disclaimers = disclaimers.map(({ item_title, item_description }) => ({ item_title, item_description }));

			// The indemnity section of the terms of service
			this.indemnity.paragraph = indemnity.paragraph;

			// The limitation and liability section of the terms of service
			this.limitation_liability.paragraph = limitation_liability.paragraph;

			// The claims of infringement section of the terms of service
			this.claims_of_infringement.starting_paragraph = claims_of_infringement.starting_paragraph;
			this.claims_of_infringement.list_items = claims_of_infringement.list_items;
			this.claims_of_infringement.ending_paragraph = claims_of_infringement.ending_paragraph;

			// The disputes section of the terms of service
			this.disputes.paragraph = disputes.paragraph;

			// The governing law and venue section of the terms of service
			this.governing_law_venue.paragraph = gaverning_law_venue.paragraph;

			// The notices section of the terms of service
			this.notices.paragraph = notices.paragraph

			// The linked sites section of the terms of service
			this.linked_sites.paragraph = linked_sites.paragraph
		
			// The additional terms section of the terms of service
			this.additional_terms.paragraph = additional_terms.paragraph
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.terms-of-use {
	background-color: #fff;
	font-family: 'Inter', sans-serif !important;
	font-weight: 400; 
	color: #040505;
	padding-top: 3vh;
}

.context-container li {
	list-style-type: none;
}

</style>
